import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "raf/polyfill";
import "core-js/features/array/flat-map";
import "core-js/features/array/find-index";
import "core-js/features/array/includes";
import "core-js/features/array/some";
import "core-js/features/object/entries";
import "core-js/features/object/values";
import "core-js/features/set";
import "core-js/features/array/find";
import "core-js/features/string/starts-with";

// Polyfills for older browsers must be imported first. Some (such as Sets) are needed for React Spring.

import React from "react";
import ReactDOM from "react-dom";

import "./index.scss";

/**
 * Preload app bundle as separate paralell module
 * this ensures core dependencies above are in a small chunk and should complete fast
 */
import(/* webpackPreload: true, webpackChunkName: "app" */ "./app").then(({ default: App }) => {
    const rootElement = document.getElementById("root");
    const render = rootElement && rootElement.hasChildNodes() ? ReactDOM.hydrate : ReactDOM.render;

    render(<App />, rootElement);
});
